/* 일반 폰트 설정 */
@font-face {
  font-family: 'MainFont';
  src: url('src/assets/font/NanumSquareAcEB.woff');
}

/* Legacy IE 브라우저용 스타일 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  @font-face {
    font-family: 'MainFont';
    src: url('src/assets/font/NanumSquareAcEB.ttf');
  }
}
